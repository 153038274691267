
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
    box-sizing: border-box;
}

body {
    background: #f6f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Lato !important;
    height: 100vh;
    margin: -20px 0 50px;
}

body h1, body h2, body p, body span, body a, body .ui.input, body .ui.button, body label{
    font-family: Lato !important;
}

h1 {
    font-weight: bold;
    margin: 0;
}

h2 {
    text-align: center;
}

.overlay-container p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

span {
    font-size: 12px;
}

a {
    /*color: #333;*/
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}


.ui.form .ui.button:active,
.overlay-container .ui.button.ghost:active {
    transform: scale(0.95) !important;
    color: #FFFFFF;
}

.ui.form .ui.button:focus,
.overlay-container .ui.button.ghost:focus {
    outline: none !important;
    color: #FFFFFF;
}

.ui.form .ui.button:hover,
.overlay-container .ui.button.ghost:hover {
    color: #FFFFFF;
}

.overlay-container .ui.button.ghost {
    background-color: transparent!important;
    border-color: #FFFFFF!important;
}

.ui.form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

.ui.form .required.field{
    width: 100%;
}

.ui.input{
    border: none;
    margin: 5px 0 !important;
    width: 100%;
}

.container {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25),
    0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 70%;
    max-width: 100%;
    height: 70%;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

/*.container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}*/

.sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

@keyframes show {
   /* 0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%, 100% {
        opacity: 1;
        z-index: 5;
    }*/
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.container.right-panel-active .overlay-container{
    transform: translateX(-100%);
    opacity: 1;
}

.overlay {
    background: #FF416C;
    background: -webkit-linear-gradient(to right, #9512a3, #978899);
    background: linear-gradient(to right, #9512a3, #978899);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
    transform: translateX(0);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-container {
    margin: 20px 0;
}

.social-container a {
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
}

@media (max-width: 768px) {
    .container{
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    .overlay-container{
        width: 100%;
        height: 25%;
    }
    .overlay-container.signIn{
        left: 0%;

    }
    .overlay-container.signUp{
        left: 100%;
    }
    .sign-in-container,
    .sign-up-container{
        width: 100%;
    }
    .ui.form{
        padding: 0 10px;
    }

    .container.right-panel-active .sign-in-container {
        transform: translateX(-100%);
    }

    .container.right-panel-active .sign-up-container {
        transform: translateX(0%);
    }

    .overlay-panel h1{
        font-size: 24px;
        margin-bottom: 5px;
    }

    .overlay-container p{
        margin: 8px 0 15px;
        line-height: 16px;
    }
}