body {
  margin: 0;
  font-family: Lato !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
}

.add-form .ui.dimmer,
.request-tabs .ui.dimmer{
  background-color: #9c9c9c99 !important;
}

code {
  font-family: Lato !important;
}



.main-section{
  width: 100%;
  height: 100%;
  background-color: #61dafb;
  position: relative;
  text-align: center;
}

.main-section .inner-section{
  width: 50%;
  background-color: beige;
  box-shadow: black;
}

.first {
  width: 40%;
  background-color: black;
  position: absolute;
}

.second {
  width: 40%;
  background-color: aqua;
  right: 0;
  position: absolute;
}
/*.about{
  width: 100px;
  height: 100px;
  position: relative;
  background-color: red;
  animation-name: example;
  animation-duration: 4s;
  animation-delay: 2s;
}*/

@keyframes  example{
  0%   {background-color:red; left:0px; top:0px;}
  25%  {background-color:yellow; left:200px; top:0px;}
  50%  {background-color:blue; left:200px; top:200px;}
  75%  {background-color:green; left:0px; top:200px;}
  100% {background-color:red; left:0px; top:0px;}
}
